import React from 'react'
import { RadioGroup } from '@headlessui/react'

import { classNames } from '../../utils/class-names'
import { onlyUnique } from '../../utils/only-unique'

export const ProductOptionSelectorColor = ({
  option,
  current,
  updateOption
}) => {
  const filtered = option.values.map(v => v.value).filter(onlyUnique)

  const onChange = v => {
    updateOption({ [option.id]: v })
  }

  return (
    <>
      <span className={`bg-black-600 bg-red-600`} />
      <div className='mt-8'>
        <div className='flex items-center justify-between'>
          <h2 className='text-sm font-medium text-gray-900'>{option.title}</h2>
        </div>

        <RadioGroup value={current} onChange={onChange} className='mt-2'>
          <RadioGroup.Label className='sr-only'>
            Choose a color
          </RadioGroup.Label>
          <span className='flex items-center space-x-3'>
            {filtered.map((v, index) => (
              <RadioGroup.Option
                key={index}
                value={v}
                className={({ active, checked }) =>
                  classNames(
                    current === v,
                    active && checked ? 'ring ring-offset-1' : '',
                    !active && checked ? 'ring-2' : '',
                    '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                  )
                }
              >
                <RadioGroup.Label as='span' className='sr-only'>
                  {v}
                </RadioGroup.Label>

                <span
                  aria-hidden='true'
                  className={classNames(
                    `bg-${v.toLowerCase()}-600`,
                    'h-8 w-8 border border-black border-opacity-10 rounded-full'
                  )}
                />
              </RadioGroup.Option>
            ))}
          </span>
        </RadioGroup>
      </div>
    </>
  )
}
export default ProductOptionSelectorColor
