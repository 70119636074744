import React from 'react'
import { RadioGroup } from '@headlessui/react'

import { classNames } from '../../utils/class-names'
import { onlyUnique } from '../../utils/only-unique'

export const ProductOptionSelectorSize = ({
  option,
  current,
  updateOption
}) => {
  const filtered = option.values.map(v => v.value).filter(onlyUnique)

  const onChange = v => {
    updateOption({ [option.id]: v })
  }

  return (
    <div className='mt-8'>
      <div className='flex items-center justify-between'>
        <h2 className='text-sm font-medium text-gray-900'>{option.title}</h2>
        <a
          href='#'
          className='text-sm font-medium text-primary-600 hover:text-primary-500'
        >
          See sizing chart
        </a>
      </div>

      <RadioGroup value={current} onChange={onChange} className='mt-2'>
        <RadioGroup.Label className='sr-only'>
          Choose a {option.title}
        </RadioGroup.Label>
        <div className='grid grid-cols-3 gap-3 sm:grid-cols-6'>
          {filtered.map((v, index) => {
            return (
              <RadioGroup.Option
                key={index}
                value={v}
                className={({ active, checked }) =>
                  classNames(
                    option.inStock ?? true
                      ? 'cursor-pointer focus:outline-none'
                      : 'opacity-25 cursor-not-allowed',
                    active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                    current === v
                      ? 'bg-primary-600 border-transparent text-white hover:bg-primary-700'
                      : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                    'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                  )
                }
                disabled={false}
              >
                <RadioGroup.Label as='span'>{v}</RadioGroup.Label>
              </RadioGroup.Option>
            )
          })}
        </div>
      </RadioGroup>
    </div>
  )
}

export default ProductOptionSelectorSize
