import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Disclosure, RadioGroup, Tab } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/solid'
import { HeartIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
import { classNames } from '../../utils/class-names'
import { toKebab } from '../../utils/to-kebab'

import { GatsbyImage } from 'gatsby-plugin-image'
import { useCart } from '../../hooks/use-cart'
import { useProduct } from '../../hooks/use-product'
import { useRegion } from '../../hooks/use-region'
import { formatPrice } from '../../utils/format-price'
import { pickDetails } from '../../utils/pick-details'

import getSymbolFromCurrency from 'currency-symbol-map'

import ProductOptionSelectorColor from './product-option-selector-color'
import ProductOptionSelectorSize from './product-option-selector-size'
import get from 'lodash/get'

export default function ProductLayout1 ({ product, pageContext, ...props }) {
  const { regionId, currencyCode, handle } = pageContext
  const details = pickDetails(product)

  const {
    loading,
    actions: { addItem }
  } = useCart()

  const {
    variant,
    options,
    quantity,
    actions: { updateOptions, increaseQuantity, decreaseQuantity, resetOptions }
  } = useProduct(product)

  const price = variant
    ? variant.prices.find(p => p.currency_code === currencyCode)
    : undefined

  const handleAddToCart = async () => {
    await addItem({ variant_id: variant.id, quantity })
    resetOptions()
  }

  const { region } = useRegion()

  useEffect(() => {
    if (region && region.id !== regionId) {
      navigate(`/${toKebab(region.name)}/${handle}`)
    }
  }, [region, handle, regionId])


  const { images } = product

  const [current, setCurrent] = useState(0)

  const handleInfiniteChange = change => {
    if (current + change < 0) {
      setCurrent(images.length - 1)
    } else if (current + change > images.length - 1) {
      setCurrent(0)
    } else {
      setCurrent(current + change)
    }
  }

  product.details = [
    {
      name: 'Features',
      items: [
        'Multiple strap configurations',
        'Spacious interior with top zip',
        'Leather handle and tabs',
        'Interior dividers',
        'Stainless strap loops',
        'Double stitched construction',
        'Water-resistant'
      ]
    },
    {
      name: 'Care',
      items: [
        'Spot clean as needed',
        'Hand wash with mild soap',
        'Machine wash interior dividers',
        'Treat handle and tabs with leather conditioner'
      ]
    },
    {
      name: 'Shipping',
      items: [
        'Free shipping on orders over $300',
        'International shipping available',
        'Expedited shipping options',
        'Signature required upon delivery'
      ]
    },
    {
      name: 'Returns',
      items: [
        'Easy return requests',
        'Pre-paid shipping label included',
        '10% restocking fee for returns',
        '60 day return window'
      ]
    }
  ]

  return (
    <div className='bg-white' itemscope itemtype='http://schema.org/Product'>
      <div className='max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start'>
          {/* Image gallery */}
          <Tab.Group as='div' className='flex flex-col-reverse'>
            {/* Image selector */}
            <div className='hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none'>
              <Tab.List className='grid grid-cols-4 gap-6'>
                {images.map(({ image }, index) => (
                  <Tab
                    key={index}
                    className='relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50'
                  >
                    {({ selected }) =>
                      image && (
                        <>
                          <GatsbyImage
                            itemprop='image'
                            image={image.childImageSharp.gatsbyImageData}
                            alt={`Product #${index + 1}`}
                            objectFit='cover'
                            objectPosition='center'
                            className='h-full w-full'
                          />
                          <span className='sr-only'>{image.name}</span>
                          <span className='absolute inset-0 rounded-md overflow-hidden'>
                            <img
                              itemprop='image'
                              src={image.src}
                              alt=''
                              className='w-full h-full object-center object-cover'
                            />
                          </span>
                          <span
                            className={classNames(
                              selected
                                ? 'ring-primary-500'
                                : 'ring-transparent',
                              'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                            )}
                            aria-hidden='true'
                          />
                        </>
                      )
                    }
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className='w-full aspect-w-1 aspect-h-1'>
              {images.map(({ image }, index) => (
                <Tab.Panel key={index}>
                  {image && (
                    <GatsbyImage
                    layout={`fixed`}
                      className='relative h-full w-full object-cover object-center'
                      imgStyle={{ objectFit: 'contain' }}
                      width={1024}
                      height={1024}
                      image={
                        images[index].image.childImageSharp.gatsbyImageData
                      }
                      //fluid={
                      //  images[index].image.childImageSharp.fluid
                      //}
                      alt={`Product #${current + 1}`}
                      onClick={() => setCurrent(index)}
                    />
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className='mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0'>
            <h1
              className='text-3xl font-extrabold tracking-tight text-gray-900'
              itemprop='name'
            >
              {product.title}
            </h1>

            <div className='mt-3'>
              <h2 className='sr-only'>Product information</h2>
              <meta itemprop="priceCurrency" content={currencyCode}/>
              <p className='text-3xl text-gray-900' itemprop="price">
                {`${getSymbolFromCurrency(currencyCode)}${formatPrice(
                  price?.amount,
                  currencyCode,
                  1
                )}`}
              </p>
            </div>

            {/* Reviews */}
            <div className='mt-3'>
              <h3 className='sr-only'>Reviews</h3>
              <div className='flex items-center'>
                <div className='flex items-center'>
                  {[0, 1, 2, 3, 4].map(rating => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        product.rating > rating
                          ? 'text-primary-500'
                          : 'text-gray-300',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden='true'
                    />
                  ))}
                </div>
                <p className='sr-only'>{product.rating} out of 5 stars</p>
              </div>
            </div>

            <div className='mt-6'>
              <h3 className='sr-only'>Description</h3>

              <div
                className='text-base text-gray-700 space-y-6'
                itemprop='description'
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </div>

            <form className='mt-6'>
              {product.options.map((option, index) => (
                <div key={index} className='mt-6'>
                  {option.title.toLowerCase() === 'color' && (
                    <ProductOptionSelectorColor
                      option={option}
                      current={get(variant, `options.${index}.value`, '')}
                      updateOption={updateOptions}
                    />
                  )}
                  {option.title.toLowerCase() === 'size' && (
                    <ProductOptionSelectorSize
                      option={option}
                      current={get(variant, `options.${index}.value`, '')}
                      updateOption={updateOptions}
                    />
                  )}
                </div>
              ))}

              <div className='mt-10 flex sm:flex-col1'>
                <button
                  type='submit'
                  className='max-w-xs flex-1 bg-primary-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:w-full'
                  onClick={() => handleAddToCart()}
                  disabled={loading}
                >
                  Add to bag
                </button>

                <button
                  type='button'
                  className='ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500'
                >
                  <HeartIcon
                    className='h-6 w-6 flex-shrink-0'
                    aria-hidden='true'
                  />
                  <span className='sr-only'>Add to favorites</span>
                </button>
              </div>
            </form>

            <section aria-labelledby='details-heading' className='mt-12'>
              <h2 id='details-heading' className='sr-only'>
                Additional details
              </h2>

              <div className='border-t divide-y divide-gray-200'>
                {product.details?.map(detail => (
                  <Disclosure as='div' key={detail.name}>
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className='group relative w-full py-6 flex justify-between items-center text-left'>
                            <span
                              className={classNames(
                                open ? 'text-primary-600' : 'text-gray-900',
                                'text-sm font-medium'
                              )}
                            >
                              {detail.name}
                            </span>
                            <span className='ml-6 flex items-center'>
                              {open ? (
                                <MinusSmIcon
                                  className='block h-6 w-6 text-primary-400 group-hover:text-primary-500'
                                  aria-hidden='true'
                                />
                              ) : (
                                <PlusSmIcon
                                  className='block h-6 w-6 text-gray-400 group-hover:text-gray-500'
                                  aria-hidden='true'
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel
                          as='div'
                          className='pb-6 prose prose-sm'
                        >
                          <ul role='list'>
                            {detail.items.map(item => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )) ?? null}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
